import css from "styled-jsx/css";
import colors from "styles/colors.js";

export const componentStyles = css.global`
  .short-footer-link-component {
    font-size: 0.8125rem;
    color: ${colors["text-gray"]};
    transition: 100ms color ease;

    &:hover {
      color: ${colors["brand-blue"]};
    }
  }
`;
