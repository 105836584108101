import { PureComponent } from "react";
import { componentStyles } from "./short-footer-link.scss";
import { withGenericConfig } from "contexts/generic-config-context";

class ShortFooterLink extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, url, translate } = this.props;

    return (
      <a className="short-footer-link-component" href={url}>
        {translate(title)}

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

export default withGenericConfig(ShortFooterLink);
