import { PureComponent } from "react";
import componentStyles from "./palette-icons-component.scss";
import SinglePaletteIconComponent from "./single-palette-icon/single-palette-icon-component";

export default class PaletteIconsComponent extends PureComponent {
  renderSocialMediaIcons() {
    const { SocialFooterLinks } = this.props;
    const socialMediasIcons = SocialFooterLinks.links;
    return Object.keys(socialMediasIcons).map((key) => {
      const socialMediaIcon = socialMediasIcons[key];
      const IconComp = socialMediaIcon.iconV2;

      return (
        <div className="single-social-icon-wrapper" key={key}>
          <SinglePaletteIconComponent
            IconComp={IconComp}
            clickableLink={socialMediaIcon.url}
            title={socialMediaIcon.title}
          />
          <style jsx>{componentStyles}</style>
        </div>
      );
    });
  }

  render() {
    return (
      <div className="palette-icons-container">
        <div className="icons-wrapper">{this.renderSocialMediaIcons()}</div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
