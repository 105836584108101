import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import LanguagePickerComponent from "/components/core/language-picker/language-picker-component";
import { MONDAY_LOGO_SRC, ROOT_LINK, SocialFooterLinks } from "/constants/links";
import PaletteIconsComponent from "segments/desktop/core-components/long-footer/footer-bottom-bar/palette-icons/palette-icons-component";
import AccessibilityStatementLinkComponent from "components/accessibility/accessibility-statement-link/accessibility-statement-link-component";
import { updateClusterShortFooterLinks } from "client/services/cluster-config-service/cluster-config-service";
import { DIALOG_DIRECTIONS } from "components/core/language-picker/language-picker-service";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component.js";
import ShortFooterLink from "./short-footer-link/short-footer-link";
import { LINKS_DATA } from "./short-footer-service";
import { componentStyles } from "./short-footer.scss";

class ShortFooter extends PureComponent {
  constructor(props) {
    super(props);
  }

  renderLinks() {
    const { localeId, pageClusterConfig, useProductMiniSiteConfig } = this.props;

    const links = LINKS_DATA;
    if (pageClusterConfig) {
      updateClusterShortFooterLinks(pageClusterConfig, links, { useProductMiniSiteConfig });
    }

    return links.map((linkData) => {
      const { title } = linkData;

      return (
        <div key={title} className="short-footer-link-component-wrapper link-wrapper">
          <ShortFooterLink localeId={localeId} {...linkData} />
        </div>
      );
    });
  }

  render() {
    const { translate } = this.props;

    return (
      <footer className="short-footer-component">
        <div className="short-footer-header">
          <div className="short-footer-links">
            {this.renderLinks()}
            <div className="accessibility-statement-link-wrapper link-wrapper">
              <AccessibilityStatementLinkComponent />
            </div>
            <div className="language-picker-wrapper-component link-wrapper">
              <LanguagePickerComponent dialogDirection={DIALOG_DIRECTIONS.ALIGN_TOP} />
            </div>
          </div>
        </div>

        <div className="monday-logo-wrapper">
          <PictureWithLinkComponent
            src={MONDAY_LOGO_SRC}
            className="footer-monday-logo-image"
            href={ROOT_LINK}
            alt="monday logo"
          />
        </div>

        <div className="contact-info-wrapper">
          <div className="all-right-reserved-info">{translate("allRightsReserved")} © monday.com</div>
        </div>

        <div className="social-proof-wrapper">
          <PaletteIconsComponent SocialFooterLinks={SocialFooterLinks} />
        </div>

        <style jsx>{componentStyles}</style>
      </footer>
    );
  }
}

export default withGenericConfig(ShortFooter);
