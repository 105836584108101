import { PureComponent } from "react";
import componentStyles from "./single-palette-icon-component.scss";

export default class SinglePaletteIconComponent extends PureComponent {
  render() {
    const { clickableLink, IconComp, title } = this.props;

    return (
      <div key={clickableLink} className="social-media-icon">
        <a target="_blank" href={clickableLink} title={title}>
          <div className="regular-icon-container">
            <IconComp />
          </div>
        </a>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
