import css from "styled-jsx/css";
import colors from "/styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export default css.global`
  .social-media-icon {
    svg {
      height: 24px;
      width: auto;
    }
    &:hover {
      svg {
        path {
          fill: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
        }
      }
    }
  }
`;
