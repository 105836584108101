import { withGenericConfig } from "/contexts/generic-config-context";
import { ACCESSIBILITY_STATEMENT_LINK } from "constants/links";
import AccessibilityIcon from "static/svg/accessibility/accessibility_icon6.svg";
import { componentStyles } from "./accessibility-statement-link-component.scss";

const AccessibilityStatementLinkComponent = (props) => {
  return (
    <div className="accessibilty-statement-link-component">
      <div className="accessibility-icon-wrapper">
        <AccessibilityIcon className="accessibility-icon" />
      </div>
      <div className="accessibility-link-wrapper">
        <a href={ACCESSIBILITY_STATEMENT_LINK} className="accessibility-link">
          {props.translate("footer.accessibiltyStatement")}
        </a>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(AccessibilityStatementLinkComponent);
