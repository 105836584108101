import {
  LEGAL_PORTAL_LINK,
  PRICING_PAGE_LINK,
  PRODUCT_PAGE_LINK,
  CONTACT_US_PAGE_LINK,
  ABOUT_US_PAGE_LINK,
  PARTNERS_PAGE_LINK,
  CAREERS_PAGE_LINK,
  BLOG_PAGE_LINK,
  INVESTOR_RELATIONS_LINK,
  CONTACT_SALES_FROM_FOOTER_PAGE_LINK
} from "/constants/links";

export const LINKS_DATA = [
  { title: "footer.aboutUs", url: ABOUT_US_PAGE_LINK },
  { title: "footer.partners", url: PARTNERS_PAGE_LINK },
  { title: "footer.careers", url: CAREERS_PAGE_LINK },
  { title: "footer.blog", url: BLOG_PAGE_LINK },
  { title: "footer.pricing", url: PRICING_PAGE_LINK },
  { title: "footer.contactSales", url: CONTACT_SALES_FROM_FOOTER_PAGE_LINK },
  { title: "footer.termsAndPrivacy", url: LEGAL_PORTAL_LINK },
  { title: "footer.product", url: PRODUCT_PAGE_LINK },
  { title: "footer.investorRelations", url: INVESTOR_RELATIONS_LINK },
  { title: "footer.contactUs", url: CONTACT_US_PAGE_LINK }
];
