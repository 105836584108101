import css from "styled-jsx/css";
import colors from "styles/colors.js";
import { MOBILE_QUERY_MAX_WIDTH, MOBILE_QUERY_MAX_WIDTH_M, MOBILE_QUERY_MIN_WIDTH } from "constants/vp-sizes";

export const componentStyles = css.global`
  .short-footer-component {
    background: ${colors["gradiant-gray"]};
    color: ${colors.black};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 48px 48px;

    .short-footer-header {
      display: flex;

      .short-footer-links {
        display: flex;
        flex-wrap: wrap;
        max-width: 1170px;
        align-items: center;
        justify-content: center;

        .link-wrapper {
          margin-top: 32px;
        }

        .short-footer-link-component-wrapper {
          margin-right: 32px;
        }

        .language-picker-wrapper-component {
          width: 120px;
        }

        .accessibility-statement-link-wrapper {
          display: flex;
          align-items: center;
          margin-right: 32px;
          padding-top: 8px;
          padding-bottom: 4px;
        }
      }
    }

    .monday-logo-wrapper {
      margin-top: 40px;
      :global(.footer-monday-logo-image) {
        height: 32px;
      }
    }

    .contact-info-wrapper {
      font-size: 0.8125rem;
      text-align: center;
    }

    .social-proof-wrapper {
      margin-top: 40px;

      .palette-icons-container {
        .icons-wrapper {
          // Original component gives margin-right hardcoded
          margin-right: 0px !important;
        }
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) and (min-width: ${MOBILE_QUERY_MIN_WIDTH}) {
    .short-footer-header {
      flex-direction: column;
      align-items: center;

      .short-footer-links {
        padding-bottom: 32px;
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    .short-footer-component {
      align-items: initial;
      padding: 32px;

      .short-footer-header {
        margin-bottom: 32px;

        .short-footer-links {
          width: 100vw;
          justify-content: start;

          .link-wrapper {
            margin-top: 0;
          }

          .short-footer-link-component-wrapper {
            margin-bottom: 32px;
            margin-top: 0px;
            width: 50%;
            margin-right: 0px;
          }
        }
      }

      .monday-logo-wrapper {
        margin-top: 0px;
      }

      .contact-info-wrapper {
        text-align: left;
        line-height: 24px;

        .address-info {
          margin-top: 0px;
        }
      }

      .social-proof-wrapper {
        margin-top: 32px;

        .palette-icons-container {
          margin-top: 0px;
        }

        .icons-wrapper {
          margin: 0px !important;
        }
      }
    }
  }
`;
