import css from "styled-jsx/css";
import colors from "styles/colors";

export const componentStyles = css.global`
  .accessibilty-statement-link-component {
    display: flex;
    font-size: 0.8125rem;
    cursor: pointer;

    .accessibility-icon-wrapper {
      display: flex;
      .accessibility-icon {
        circle,
        path {
          color: ${colors["text-gray"]};
          transition: stroke 150ms ease;
        }
      }
    }

    .accessibility-link-wrapper {
      padding-left: 8px;
      display: flex;
      align-items: center;
      .accessibility-link {
        transition: 150ms color ease;
        color: ${colors["text-gray"]};
      }
    }

    &:hover {
      .accessibility-icon-wrapper {
        .accessibility-icon {
          path,
          circle {
            stroke: ${colors.iris};
          }
        }
      }

      .accessibility-link-wrapper {
        .accessibility-link {
          color: ${colors.iris};
        }
      }
    }
  }
`;
